// export const API_BASE_URL = 'http://192.168.10.112:8000';

// export const API_BASE_URL = 'http://192.168.1.103:8000';

//export const API_BASE_URL = 'http://192.168.68.189:8000';

// export const API_BASE_URL = 'http://192.168.121.103:8000';

export const API_BASE_URL = 'https://server.smartsilid.site';

// export const API_BASE_URL = 'http://192.168.60.112:8000';

//export const API_BASE_URL = 'https://5009-120-28-251-222.ngrok-free.app';

// export const API_BASE_URL = 'https://d7b6-120-28-251-222.ngrok-free.app';

// export const API_BASE_URL = 'http://192.168.1.13:8000';

// export const API_BASE_URL = 'http://10.10.50.143:8000';

